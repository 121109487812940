@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/ui/src/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    @apply text-xs xs:text-base;
  }

  body {
    @apply text-gray-500 font-primary dark:text-gray-0;
  }
}
